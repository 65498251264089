<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col sm="6" md="8">
        <div>
          <h1>DRIVE TO SHOP</h1>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mb-p">
        <p><b>Address:</b> {{ evaluation_info.address }}</p>
        <p><b>City / ST:</b> {{ evaluation_info.city }}</p>
        <p><b>Phone:</b> {{ evaluation_info.phone }}</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="10" class="mb-p">
        <p>
          Drive Time from Current Location: {{ evaluation_info.drive_time }}
        </p>
        <p>Projected Arrival Time: {{ evaluation_info.arrival_time }}</p>
      </v-col>
    </v-row>
    <v-row class="space-between">
      <v-col md="2" sm="6">
        <div>
          <v-btn color="primary" dark block>Click to Drive</v-btn>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div>
          <v-btn color="primary" dark block>Refresh</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <googleMap />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import googleMap from "../../components/Map/Map";

export default {
  name: "EvaluationReschedule.vue",
  components: { googleMap },
  data() {
    return {
      evaluation_info: {
        address: "7500 Blue Ridge Blvd",
        city: "Kansas City, MO 64138",
        phone: "816-682-8440",
        drive_time: "12 minutes",
        arrival_time: "09:46:00 AM",
      },
    };
  },
};
</script>

<style scoped></style>
